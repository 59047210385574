import React from "react";
import Header from "./Header";
import EntryForm from "./EntryForm";

const EntryPage = () => {
  return (
    <div className="flex flex-col w-[280px] justify-center">
      <EntryForm className={'w-[280px]'} />
    </div>
  );
};

export default EntryPage;