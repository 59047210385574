import Number1Img from "../assets/images/numbers/1.png";
import Number2Img from "../assets/images/numbers/2.png";
import Number3Img from "../assets/images/numbers/3.png";
import Number4Img from "../assets/images/numbers/4.png";
import Number5Img from "../assets/images/numbers/5.png";
import Number6Img from "../assets/images/numbers/6.png";
import Number7Img from "../assets/images/numbers/7.png";
import Number8Img from "../assets/images/numbers/8.png";
import EntryQuestion from "../assets/images/question.png";
import RedImg from "../assets/images/lureSelect/Red.png";
import BlackImg from "../assets/images/lureSelect/Black.png";
import BlueImg from "../assets/images/lureSelect/Blue.png";
import BrownImg from "../assets/images/lureSelect/Brown.png";
import GreenImg from "../assets/images/lureSelect/Green.png";
import GreySilverImg from "../assets/images/lureSelect/GreySilver.png";
import OrangeImg from "../assets/images/lureSelect/Orange.png";
import PinkImg from "../assets/images/lureSelect/Pink.png";
import PurpleImg from "../assets/images/lureSelect/Purple.png";
import YellowGoldImg from "../assets/images/lureSelect/YellowGold.png";
import WhiteImg from "../assets/images/lureSelect/White.png";

export const ImgData = {
  numbers: {
    one: Number1Img,
    two: Number2Img,
    three: Number3Img,
    four: Number4Img,
    five: Number5Img,
    six: Number6Img,
    seven: Number7Img,
    eight: Number8Img,
  },
  question: EntryQuestion,
  colors: {
    Red: RedImg,
    Black: BlackImg,
    Blue: BlueImg,
    Brown: BrownImg,
    Green: GreenImg,
    GreySilver: GreySilverImg,
    Orange: OrangeImg,
    Pink: PinkImg,
    Purple: PurpleImg,
    YellowGold: YellowGoldImg,
    White: WhiteImg,
  },
};

export const checkJWTTokenValidation = (jwtData) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the current timestamp is greater than the expiration timestamp
  if (currentTimestamp > jwtData.exp) {
    return false;
  } else {
    return true;
  }
}

export const convetCardDate = (dateStr) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Convert string to Date object
  const dateObj = new Date(dateStr);

  // Extract date components
  const dayOfWeek = days[dateObj.getDay()];
  const month = months[dateObj.getMonth()];
  const dayOfMonth = dateObj.getDate();
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  // Convert hours to 12-hour format and determine AM/PM
  const amOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  // Format the date
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth} at ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;

  return formattedDate;
}

export const convertToJPEG = (file) => {
  const reader = new FileReader();
  reader.onload = function (event) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Convert image to JPEG format with quality 0.8
      canvas.toDataURL('image/jpeg', 0.8);
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
};

export function formatPostedDate(inputDate) {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const date = new Date(inputDate);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = months[monthIndex];
  const daySuffix = getDaySuffix(day);

  return `Posted ${getDayOfWeek(date)}, ${month} ${day}${daySuffix}`;
}

function getDayOfWeek(date) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[date.getDay()];
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}