import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { convetCardDate } from '../../../utils';
import { IconButton } from '@mui/material';
import toast from 'react-hot-toast';

const baseURL = process.env.REACT_APP_API_URL + "/api/"

export default function EntryAdminCard({ type, id, image, submitted, name, email, item, comment, fetchEntryItems }) {

    const [imageUrl, setImageUrl] = React.useState(image);
    const [imageFile, setImageFile] = React.useState(null);

    const rotateImage = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.crossOrigin = 'anonymous'; // Enable CORS
        img.onload = function () {
            // Set canvas dimensions to match the image rotated 90 degrees
            canvas.width = img.height;
            canvas.height = img.width;

            // Clear previous image
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Rotate the image
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI / 2); // Rotate by 90 degrees (in radians)
            ctx.drawImage(img, -img.width / 2, -img.height / 2);

            // Get rotated image as data URL
            const rotatedImageUrl = canvas.toDataURL('image/jpeg');

            // Update state with rotated image URL
            setImageUrl(rotatedImageUrl);

            canvas.toBlob((blob) => {
                const rotatedImageFile = new File([blob], 'rotated_image.jpg', { type: 'image/jpeg' });
                setImageFile(rotatedImageFile);
            }, 'image/jpeg');
        };

        img.src = imageUrl;
    };

    const rotatedImageSave = async () => {
        const formData = new FormData();
        formData.append("rotate_image", imageFile);
        formData.append("id", id);

        await axios.put(`entry/image_rotate`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            baseURL
        });

        toast.success("Saved");
    }

    const approve = async () => {
        try {
            await axios.put("entry/approve", {
                id
            }, {
                baseURL
            })
            fetchEntryItems();
        } catch (error) {
            console.log(error)
        }
    }

    const reject = async () => {
        try {
            await axios.put("entry/reject", {
                id
            }, {
                baseURL
            })
            fetchEntryItems();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Card sx={{ width: 320, padding: "10px", display: "flex", flexDirection: "column" }}>
            <PhotoProvider>
                <PhotoView src={imageUrl}>
                    <img src={imageUrl} alt='entry' className="w-full" />
                </PhotoView>
            </PhotoProvider>
            <CardContent>
                <Button variant='contained' startIcon={<CameraswitchIcon />} sx={{ margin: "10px 0px" }} onClick={rotateImage}>Rotate</Button>
                <IconButton aria-label="save" color='primary' onClick={() => rotatedImageSave()}>
                    <SaveIcon />
                </IconButton>
                <table className="table-fixed text-[14px]">
                    <tbody>
                        <tr className=''>
                            <td className='text-right pr-[10px] flex justify-end'>Submitted</td>
                            <td className='text-left font-bold'>{convetCardDate(submitted)}</td>
                        </tr>
                        <tr>
                            <td className='text-right pr-[10px] flex justify-end'>Name</td>
                            <td className='text-left font-bold'>{name}</td>
                        </tr>
                        <tr>
                            <td className='text-right pr-[10px] flex justify-end'>Email</td>
                            <td className='text-left font-bold'>{email}</td>
                        </tr>
                        <tr>
                            <td className='text-right pr-[10px] flex justify-end'>Item</td>
                            <td className='text-left font-bold'>{item}</td>
                        </tr>
                        <tr>
                            <td className='text-right pr-[10px] flex justify-end'>Comments</td>
                            <td className='text-left font-bold'>{comment}</td>
                        </tr>
                    </tbody>
                </table>

            </CardContent>
            <CardActions className='flex justify-center gap-2'>
                <Button type='button' variant='contained' color='error' startIcon={<CloseIcon />} onClick={() => reject()} disabled={type === "Rejected"}>Reject</Button>
                <Button type='button' variant='contained' color='success' startIcon={<DoneIcon />} onClick={() => approve()} disabled={type === "Approved"}>Approve</Button>
            </CardActions>
        </Card>
    );
}