import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export const getLuresData = async () => {
    try {
        const { data: luresData } = await axios.get(`${API_URL}/api/lures`);
        return luresData;
    } catch (err) {
        console.error("error:", err);
        return []
    }
}