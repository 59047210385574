import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useIsAuthenticated } from "./auth";

export function useGuard(authState, redirectPath) {

  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(
    () => {
      if (isAuthenticated !== authState) {
        navigate(redirectPath);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated],
  );
}
