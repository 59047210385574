
import React, { createContext, useState } from "react";


export const LureContext = createContext();

export const LuresProvider = ({ children }) => {
    const [luresModalIsOpen, setLuresModalIsOpen] = useState(false);
    const [lureSelectedItem, setLureSelectedItem] = useState(null);
    

    

    return (
        <LureContext.Provider
            value={{
                luresModalIsOpen,
                lureSelectedItem,
                setLuresModalIsOpen,
                setLureSelectedItem,
            }}
        >
            {children}
        </LureContext.Provider>
    );
};
