import React from "react";
import { useLogout } from "../../../store/hooks/auth";

import LogoImg from "../../../assets/images/logo.png"

const AdminDashboardNavbar = () => {
    const logout = useLogout();

    return (
        <nav className="bg-white dark:bg-gray-900 w-full z-20 border-b border-gray-200 dark:border-gray-600 px-5">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
                <div className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={LogoImg} className="max-w-[200px] md:max-w-[250px] cursor-pointer" alt="" />
                </div>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={logout}>Logout</button>
                </div>
            </div>
        </nav>
    )
}

export default AdminDashboardNavbar;