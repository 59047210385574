import { useDispatch } from "react-redux";

import { actions } from "../slices/auth";

import useStoreSelector from "./useStoreSelector";

export function useLogin() {
  const dispatch = useDispatch();
  return (payload) => dispatch(actions.login(payload));
}

export function useLogout() {
  const dispatch = useDispatch();
  return () => dispatch(actions.logout());
}

export function useAccessToken() {
  return useStoreSelector(({ auth }) => auth.accessToken);
}

export function useIsAuthenticated() {
  return !!useAccessToken();
}
