import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import EntryPage from "../../components/EntryPage";
import PostCard from "../../components/PostCard";
import axios from "axios";
import { debounce } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

const HomePage = () => {
  const [entryItems, setEntryItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  // Define fetchEntryItems before debouncedFetchMoreItems
  const fetchEntryItems = async () => {
    try {
      const { data: entryItemsData } = await axios.get(`${API_URL}/api/entry`, {
        params: {
          offset: offset,
          limit: limit
        }
      });
      setEntryItems(prevItems => [...prevItems, ...entryItemsData]);
      setOffset(prevOffset => prevOffset + limit);
    } catch (error) {
      console.error("Error fetching entry items:", error);
    }
  };

  // Now you can use fetchEntryItems within debouncedFetchMoreItems
  const debouncedFetchMoreItems = debounce(fetchEntryItems, 500);

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      debouncedFetchMoreItems();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchEntryItems();
  }, []);

  return (
    <div className="flex gap-[15px] max-w-[850px] w-full max-[900px]:px-[5%] xs:flex-row flex-col-reverse">
      <div className="w-full flex-[2_2_0%] justify-end mt-12 xs:mt-0">
        {entryItems && (
          <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 800: 2 }}>
            <Masonry gutter="10px" className="pt-[50px]">
              {entryItems.map((entryItem, index) => (
                <PostCard entryItem={entryItem} key={index} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        )}
      </div>
      <div className="flex flex-1 items-start justify-center xs:justify-start">
        <EntryPage />
      </div>
    </div>
  );
};

export default HomePage;