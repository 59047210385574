export const searchLures = (lures, filterOptions) => {
    const { searchColorInput, selectedColors } = filterOptions;
    let filteredData = [];

    if (searchColorInput === "" && selectedColors.length === 0) {
        return []
    }

    if (searchColorInput !== "" && selectedColors.length === 0) {
        lures.forEach(obj => {
            const { description } = obj;
            const hasSearchColor = searchColorInput !== "" ? description.toLowerCase().includes(searchColorInput.toLowerCase()) : false;
            if (searchColorInput !== "" && selectedColors.length === 0) {
                if (hasSearchColor) {
                    filteredData.push(obj);
                }
            }
        });
    }

    if (searchColorInput !== "" && selectedColors.length > 0) {
        lures.forEach(obj => {

            const { description } = obj;
            let hasSelectedColors = [];

            if (selectedColors.length > 0) {
                hasSelectedColors = selectedColors.every(color => description.toLowerCase().includes(color.toLowerCase()));
            }

            if (hasSelectedColors) {
                filteredData.push(obj);
            }
        })

        filteredData = filteredData.filter((item) => item.description.toLowerCase().includes(searchColorInput.toLowerCase()))
    }

    if (searchColorInput === "" && selectedColors.length > 0) {
        lures.forEach(obj => {

            const { description } = obj;
            let hasSelectedColors = [];

            if (selectedColors.length > 0) {
                hasSelectedColors = selectedColors.every(color => description.toLowerCase().includes(color.toLowerCase()));
            }

            if (hasSelectedColors) {
                filteredData.push(obj);
            }
        })
    }

    return filteredData;
}
