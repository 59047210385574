import axios from "axios";

import { useSetupAxios } from "../store/hooks/useSetupAxios";

export default function AppLayout({ children }) {
    useSetupAxios(axios);
    return (
        <>
            {children}
        </>
    );
}
