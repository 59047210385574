import React, { useEffect, useRef, useState } from "react";

import EnterHereImg from "../../../assets/images/enter_here.png";
import { ImgData } from "../../../utils";
import axios from "axios";
import toast from "react-hot-toast";
import useLuresProvider from "../../../hooks/useLuresProvider";
import Button from '@mui/material/Button';

const API_URL = process.env.REACT_APP_API_URL;

const initEntryData = {
  imgFile: null,
  originFile: null,
  comments: "",
  firstname: "",
  lastname: "",
  email: ""
}

const EntryForm = ({ className }) => {

  const [entryData, setEntryData] = useState(initEntryData);
  const [entryImgPreviewUrl, setEntryImgPreviewUrl] = useState(null);
  const [agreeStatus, setAgreeStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const { lureSelectedItem, setLuresModalIsOpen } = useLuresProvider();
  const entryImgRef = useRef(null);

  const handleFileClicked = () => {
    entryImgRef.current.click();
  }

  const handleEntryImageChanged = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // FileReader for originFile
      const originFileReader = new FileReader();
      originFileReader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const jpegDataURL = canvas.toDataURL('image/jpeg', 1.0);
          const blob = await fetch(jpegDataURL).then((res) => res.blob());
          const jpegFile1 = new File([blob], "origin.jpeg", { type: "image/jpeg" });

          // FileReader for imgFile
          const imgFileReader = new FileReader();
          imgFileReader.onload = async (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = async () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const targetWidth = img.width * 0.2;
              const targetHeight = img.height * 0.2;
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
              const jpegDataURL = canvas.toDataURL('image/jpeg', 1.0);
              const blob = await fetch(jpegDataURL).then((res) => res.blob());
              const jpegFile = new File([blob], "resized_image.jpeg", { type: "image/jpeg" });

              // Set both imgFile and originFile in entryData state
              setEntryData({ ...entryData, imgFile: jpegFile, originFile: jpegFile1 });
            };
          };
          imgFileReader.readAsDataURL(file);
        };
      };
      originFileReader.readAsDataURL(file);
    } else {
      setEntryData({ ...entryData, imgFile: null })
    }
  }


  const handleEntryFormChanged = (e) => {
    setEntryData({ ...entryData, [e.target.name]: e.target.value })
  }

  const handleSelectLureClicked = () => {
    setLuresModalIsOpen(true);
  }

  const handleSubmit = async () => {
    try {

      if (!agreeStatus || loading) {
        return;
      }

      setLoading(true);

      const requiredInputs = ["comments", "email", "firstname", "lastname", "imgFile", "lureSelectedItem._id"];
      const isAllInputsFilled = requiredInputs.every((input) => entryData[input] !== "");

      if (!isAllInputsFilled) {
        toast.error("All inputs must be filled!");
        return;
      }

      const formData = new FormData();
      formData.append("comment", entryData.comments);
      formData.append("email", entryData.email);
      formData.append("firstName", entryData.firstname);
      formData.append("lastName", entryData.lastname);
      formData.append("lures", lureSelectedItem._id);
      formData.append("image", entryData.imgFile);
      formData.append("origin_image", entryData.originFile);


      console.log(entryData.imgFile, entryData.originFile)

      await axios.post(`${API_URL}/api/entry/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Success!");
    } catch (err) {
      toast.error("An error occurred. Please try again later.");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (!entryData.imgFile) {
      return
    }

    const reader = new FileReader()

    reader.onloadend = () => {
      setEntryImgPreviewUrl(reader.result)
    }

    reader.readAsDataURL(entryData.imgFile)
  }, [entryData.imgFile])

  return (
    <div className={`${className} entry-form flex flex-col gap-10 shadow-2xl`}>
      <img src={EnterHereImg} className="enter-here" alt="enter here" />
      <div className="bg-white color-black text-left rounded hidden xs:flex flex-col gap-3 font-bold mx-4 px-3 py-4 leading-[20px] text-[15px]">
        <p>
          Get your Catch Pic tweeted and posted to our 2000+ followers and fans
          AND a chance to win a "Taz Lures 7 Gram MultiPack with Free
          Shipping" (a $29 value!) in Monthly Photo Contest!
        </p>
        <p>
          Every month Tom will choose one winner based on 3 criteria:
          originality, type of catch, and overall cool factor, (Extra points for
          the Taz Lure in the photo.) ANY SIZE FISH CAN WIN!
        </p>
        <p>
          You may enter one different photo per day depicting you and/or your
          catch using a Taz Lure.
        </p>
        <p>Fill out the form below to enter:</p>
      </div>

      <div className="w-full flex flex-col gap-7">
        <div className="flex justify-center relative items-center">
          <img
            src={ImgData.numbers.one}
            alt="one"
            className="absolute left-2 w-[25px]"
          />
          <input type="file" className="hidden" ref={entryImgRef} onChange={handleEntryImageChanged} />
          {entryData.imgFile ? <img src={entryImgPreviewUrl} className="w-[70%] rounded" alt="" onClick={handleFileClicked} /> : <div className="photo-upload" onClick={handleFileClicked} />}
        </div>
        <div className="flex flex-col justify-center relative items-center">
          <img
            src={ImgData.numbers.two}
            alt="two"
            className="absolute left-2 top-2 w-[25px]"
          />
          <img src={ImgData.question} alt="question" />
          {lureSelectedItem ?
            <div className="flex flex-col gap-2">
              <div className="bg-white w-[150px] p-3 flex flex-col items-center" onClick={handleSelectLureClicked}>
                <img src={`/assets/images-items/${lureSelectedItem.image}`} alt="" className="w-[80px] h-[150px] cursor-pointer" />
              </div>
              <span className="text-center text-white">{lureSelectedItem.itemName}</span>
            </div>
            : <div className="select-lure cursor-pointer w-[73%]" onClick={handleSelectLureClicked}></div>}
        </div>
        <div className="flex justify-center relative items-center">
          <img
            src={ImgData.numbers.three}
            alt="three"
            className="absolute left-2 w-[25px]"
          />
          <textarea
            id="message"
            rows="5"
            className="outline-none text-lg block p-2.5 w-[200px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Comments"
            name="comments"
            onChange={handleEntryFormChanged}
          ></textarea>
        </div>
        <div className="flex justify-center relative items-center">
          <img
            src={ImgData.numbers.four}
            alt="four"
            className="absolute left-2 w-[25px]"
          />
          <input
            type="text"
            id="first_name"
            className="!w-[200px] bg-gray-50 text-[18px] outline-none border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="First Name"
            name="firstname"
            onChange={handleEntryFormChanged}
            required
          />
        </div>
        <div className="flex justify-center relative">
          <img
            src={ImgData.numbers.five}
            alt="five"
            className="absolute left-2 w-[25px]"
          />
          <div>
            <input
              type="text"
              id="last_name"
              className="!w-[200px] bg-gray-50 text-[18px] outline-none border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Last Name"
              name="lastname"
              onChange={handleEntryFormChanged}
              required
            />
            <p className="text-center text-[13px] text-white w-[200px]">
              Your last name wil not be posted
            </p>
          </div>
        </div>
        <div className="flex justify-center relative">
          <img
            src={ImgData.numbers.six}
            alt="six"
            className="absolute left-2 w-[25px]"
          />
          <div>
            <input
              type="email"
              id="email"
              className="!w-[200px] bg-gray-50 text-[18px] outline-none border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full px-1.5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Email Address"
              name="email"
              onChange={handleEntryFormChanged}
              required
            />
            <p className="text-center text-[13px] text-white w-[200px]">
              We'll contact you here if you win, don't worry. no spam
            </p>
          </div>
        </div>
        <div className="flex flex-col px-2">
          <span className="text-lg text-white font-bold text-left pl-8">
            I Agree
          </span>
          <div className="flex justify-between pr-6 relative">
            <img src={ImgData.numbers.seven} alt="seven" className="w-[25px] h-[30px] mt-[22px]" />
            <span className="text-white text-[13px] leading-[20px] w-full text-left">
              <input
                id="default-checkbox"
                type="checkbox"
                value={agreeStatus}
                onClick={() => setAgreeStatus(!agreeStatus)}
                className="w-6 h-6 float-left text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer m-[25px]"
              />
              By checking this box and submitting this form you agree to the{" "}
              <a className="text-blue-400 underline cursor-pointer whitespace-nowrap" href="https://tomstazlures.com/rules" target="_blank" rel="noreferrer">
                Oneline Rules & Term
              </a>{" "}
              and to allow Tom's Taz Lures to post your uploaded photo, your first
              name, and comments on Twitter, Facebook,, & marketing campaigns,
              VOID WHERE PROHIBITED.
            </span>
          </div>
        </div>
        <div className="flex justify-center relative items-center pt-10">
          <img
            src={ImgData.numbers.eight}
            alt="eight"
            className="absolute left-2 w-[25px]"
          />
          <Button variant="contained" color="success" sx={{ width: "70%", height: "50px", fontSize: "20px", cursor: !agreeStatus || loading ? "not-allowed" : "pointer" }} onClick={handleSubmit}>
            {loading ? "Sending..." : "Send Entry"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EntryForm;