import React, { useEffect, useState } from "react";
import Modal from 'react-modal';

import { ImgData } from "../../utils";
import { getLuresData } from "../../api/lures";
import { searchLures } from "../../utils/search_lures";
import "./index.css";
import useLuresProvider from "../../hooks/useLuresProvider";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const colors = [
  "Red",
  "Orange",
  "YellowGold",
  "Green",
  "Blue",
  "Purple",
  "Pink",
  "Black",
  "White",
  "Brown",
  "GreySilver",
];

const LureColorSearch = () => {
  const { luresModalIsOpen, setLuresModalIsOpen, setLureSelectedItem } = useLuresProvider();
  const [searchColorInput, setSearchColorInput] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [luresData, setLuresData] = useState([]);
  const [viewItems, setViewItems] = useState([])

  function closeModal() {
    setLuresModalIsOpen(false);
  }

  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) => {
      if (prevSelectedColors.includes(color)) {
        return prevSelectedColors.filter((c) => c !== color); // Remove color if already selected
      } else {
        return [...prevSelectedColors, color]; // Add color if not selected
      }
    });
  };

  const initializeLures = async () => {
    const lures = await getLuresData();
    setLuresData(lures);
  }

  const handleSearchInputChange = (e) => {
    setSearchColorInput(e.target.value);
  }

  const handleLureClicked = (viewItem) => {
    setLureSelectedItem(viewItem);
    setLuresModalIsOpen(false);
  }

  useEffect(() => {
    initializeLures()
  }, []);

  useEffect(() => {
    const result = searchLures(luresData, {
      searchColorInput, selectedColors
    });
    setViewItems(result);
  }, [searchColorInput, selectedColors, luresData])

  return (
    <Modal
      isOpen={luresModalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Modal"
    >
      <div className="flex justify-center">
        <div className="lure-color-serach py-5 max-w-[500px]">
          <div className="lure-color-search-section text-center">
            <span className="text-[20px] font-bold text-center">
              You can search by name or lure number, or select the colors of the
              lure below.
            </span>
          </div>
          <div className="lure-color-search-section px-2">
            <div className="max-w-md mx-auto">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full text-[22px] my-2 p-3 ps-10 text-sm outline-none text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search"
                  value={searchColorInput}
                  onChange={handleSearchInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="lure-color-search-section">
            <div className="flex flex-wrap justify-center gap-1">
              {colors.map((color, index) => {
                return (
                  <div
                    className={`w-[80px] color-border ${selectedColors.includes(color) && "color-item-clicked"
                      }`}
                    key={index}
                  >
                    <img
                      src={ImgData.colors[color]}
                      alt={color}
                      className={`cursor-pointer`}
                      onClick={() => handleColorClick(color)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`flex flex-wrap gap-2 my-5 justify-center px-1`}>
            {viewItems.slice(0, 6).map((viewItem, index) => {
              return (
                <div className="flex flex-col items-center">
                  <div className="bg-white w-[150px] p-3 flex flex-col items-center" key={index} onClick={() => handleLureClicked(viewItem)}>
                    <img src={`/assets/images-items/${viewItem.image}`} alt="" className="w-[80px] h-[150px] cursor-pointer" />
                  </div>
                  <span className="text-center underline">{viewItem.itemName}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Modal>

  );
};


export default LureColorSearch;