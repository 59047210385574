import React, { useEffect, useState } from "react";

const Navbar = () => {

  const [isShow, setIsShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleMobileNavbar = () => {
    setIsShow(!isShow);
  }

  useEffect(() => {
    if (width > 1024) {
      setIsShow(false);
    }
  }, [width])

  return (
    <div className={"navbar" + (isShow ? " !h-full !pb-4" : "")}>
      <div className="flex justify-center">
        <div className="container-lg flex justify-between w-[1024px]">
          <div className="header_logo"></div>
          <div className="justify-center hidden lg:flex">
            {/* <div className="mystrey_button"></div>
            <div className="lure_button"></div>
            <div className="phone_button"></div> */}
            <div className="store_button"></div>
          </div>
        </div>
        <button data-collapse-toggle="navbar-hamburger" type="button" className="inline-flex items-center justify-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden mt-4" aria-controls="navbar-hamburger" aria-expanded={isShow} onClick={handleMobileNavbar}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
      </div>

      <div className={(isShow ? "flex flex-col items-center lg:hidden" : "hidden") + " w-full"} id="navbar-hamburger">
        {/* <div className="mystrey_button"></div>
        <div className="lure_button"></div>
        <div className="phone_button"></div> */}
        <div className="store_button"></div>
      </div>
    </div>
  );
};


export default Navbar;