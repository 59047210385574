import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {};

export const { actions, ...slice } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => payload,
    logout: () => initialState,
  },
});

export const reducer = persistReducer(
  {
    key: "tomstazlures-auth",
    version: 1,
    storage,
  },
  slice.reducer,
);
