import React from "react";
import { formatPostedDate } from "../../utils";

const PostCard = ({ entryItem }) => {
    return (
        <div className="flex flex-col justify-center items-center rounded shadow bg-white p-2.5 post-card mt-4">
            <div className="w-full flex flex-col border-2 border-black rounded-[3px]">
                <div className="w-full px-2 py-1 flex items-center justify-between bg-black">
                    <span className="text-yellow-300">{formatPostedDate(entryItem.createdAt)}</span>
                    <div className="flex gap-1 items-center">
                        <span className="card-twitter-icon cursor-pointer"></span>
                        <span className="card-facebook-icon cursor-pointer"></span>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={entryItem.post_image} className="w-full" alt="test img" draggable={false} />
                </div>
            </div>
            <div className="relative flex flex-col ml-3 w-full">
                <span className="leading-6 text-[18px] mt-2 text-left pl-10 pr-3 w-full">{entryItem.comment}</span>
                <span className="speedy-font text-right text-[35px] mt-2 font-bold pr-3 entry-sign">- {entryItem.firstName}</span>
                <span className="text-right text-[20px] font-bold entry-item-id pl-[30px] pr-3 underline">{entryItem.lures.description.toString().split("c:")[0]}</span>
                <a href="https://lures.com/xxx" target="_blank"><img src={`/assets/images-items/${entryItem.lures.image}`} className="absolute bottom-[-60px] left-[-60px] w-[95px] cursor-pointer post-card-lure-img" alt="img" /></a>
            </div>
        </div>
    )
}

export default PostCard;