import React, { useEffect, useState } from "react";
import axios from "axios";

import { useGuard } from "../../../store/hooks";
import { useIsAuthenticated } from "../../../store/hooks/auth";
import { useSetupAxios } from "../../../store/hooks/useSetupAxios";
import AdminDashboardNavbar from "./navbar";
import ShowItems from "./show_items";

const baseURL = process.env.REACT_APP_API_URL + "/api";

const AdminDashboard = () => {
    useSetupAxios(axios);
    const isAuthenticated = useIsAuthenticated()

    useGuard(true, "/login");

    const [entryItems, setEntryItems] = useState([]);

    const fetchEntryItems = async () => {
        try {
            const { data: entry_items } = await axios.get("entry/all", { baseURL });
            setEntryItems(entry_items);
        } catch (error) {

        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchEntryItems()
        }
    }, [isAuthenticated])

    return (
        <div className="w-full h-full admin-dashboard">
            <AdminDashboardNavbar />
            <ShowItems entryItems={entryItems} fetchEntryItems={fetchEntryItems} />
        </div>
    )
}

export default AdminDashboard;