import React from "react";
import EntryAdminCard from "./entry_card";
import Divider from '@mui/material/Divider';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ShowItems = ({ entryItems, fetchEntryItems }) => {
    return (
        <div className="w-full flex flex-col items-center justify-center mt-[150px] pb-[150px]">
            <div className="w-[95%] flex flex-col items-center mt-5">
                <div className="w-full mb-10">
                    <h1 className="text-white text-[25px] text-left">Waiting</h1>
                    <Divider color="white" />
                </div>
                <div className="w-full flex justify-center flex-wrap gap-4 items-start">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 1060: 3 }}
                        className="w-[1024px]"
                    >
                        <Masonry gutter="10px" className="admin-entry-masonry">
                            {entryItems?.filter((entry_item) => entry_item.approveStatus === 0).map((entry_item, index) => {
                                return (
                                    <EntryAdminCard type="Waiting" id={entry_item._id} image={entry_item.post_image} comment={entry_item.comment} email={entry_item.email} name={entry_item.firstName} submitted={entry_item.createdAt} item={entry_item.lures.itemName} key={index} fetchEntryItems={fetchEntryItems} />
                                )
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
            <div className="w-[95%] flex flex-col items-center mt-5">
                <div className="w-full mb-10">
                    <h1 className="text-white text-[25px] text-left">Approved</h1>
                    <Divider color="white" />
                </div>
                <div className="w-full flex justify-center flex-wrap gap-4 items-start">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 1060: 3 }}
                        className="w-[1024px]"
                    >
                        <Masonry gutter="10px" className="admin-entry-masonry">
                            {entryItems?.filter((entry_item) => entry_item.approveStatus === 1).map((entry_item, index) => {
                                return (
                                    <EntryAdminCard type="Approved" id={entry_item._id} image={entry_item.post_image} comment={entry_item.comment} email={entry_item.email} name={entry_item.firstName} submitted={entry_item.createdAt} item={entry_item.lures.itemName} key={index} fetchEntryItems={fetchEntryItems} />
                                )
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
            <div className="w-[95%] flex flex-col items-center mt-5">
                <div className="w-full mb-10">
                    <h1 className="text-white text-[25px] text-left">Rejected</h1>
                    <Divider color="white" />
                </div>
                <div className="w-full flex justify-center flex-wrap gap-4 items-start">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 1060: 3 }}
                        className="w-[1024px]"
                    >
                        <Masonry gutter="10px" className="admin-entry-masonry">
                            {entryItems?.filter((entry_item) => entry_item.approveStatus === 2).map((entry_item, index) => {
                                return (
                                    <EntryAdminCard type="Rejected" id={entry_item._id} image={entry_item.post_image} comment={entry_item.comment} email={entry_item.email} name={entry_item.firstName} submitted={entry_item.createdAt} item={entry_item.lures.itemName} key={index} fetchEntryItems={fetchEntryItems} />
                                )
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </div>
    )
}

export default ShowItems;