import Navbar from "./pages/Navbar";
import HomePage from "./pages/HomePage";
import LureColorSearch from "./components/LureColorSearch";
import { Toaster } from "react-hot-toast";
import { LuresProvider } from "./contexts/lureContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthLogin from "./pages/Admin/auth";
import AdminDashboard from "./pages/Admin/Dashboard";
import AppLayout from "./layouts";

import "./App.css";

function App() {
  return (
    <div className="App">
      <AppLayout>
        <LuresProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<>
                <Navbar />
                <div className="w-full flex flex-col justify-center items-center">
                  <HomePage />
                  <LureColorSearch />
                </div>
              </>}></Route>
              <Route path="/login" element={<AuthLogin />}>
              </Route>
              <Route path="/admin" element={<AdminDashboard />}></Route>
            </Routes>
            <Toaster position="top-center" />
          </BrowserRouter>
        </LuresProvider>
      </AppLayout>
    </div>
  );
}

export default App;
